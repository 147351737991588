import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../components/images/Sprits/icons';
import '../assets/global-style/style.scss';
import Navigation from '../components/structure/Navigation';
import { toggleBlockBody, unBlockBody } from '../utils/utils';

const Global = () => {
    const $navigation = document.querySelector('.js-navigation');
    const $menu = document.querySelector('.js-menu');
    const $menuClosed = document.querySelector('.js-menu-closed');
    const menuItems = document.querySelectorAll('.js-menu-item');

    if ($navigation) {
        new Navigation($navigation, toggleAside);
        $menuClosed.addEventListener('click', closeMenu);

        menuItems.forEach(($menuItem) => {
            $menuItem.addEventListener('click', closeMenu);
        });
    }

    function toggleAside() {
        $menu.classList.toggle('js-menu-active');
        toggleBlockBody();
    }

    function closeMenu() {
        $menu.classList.remove('js-menu-active');
        unBlockBody();
    }
};

Global();
