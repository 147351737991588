import './navigation.scss';

export default class Navigation {
    constructor(navigation, showAside) {
        this.$navigation = navigation;
        this.$burger = this.$navigation.querySelector('.js-navigation-burger');
        this.burgerAction = showAside;
        this.menuItems = this.$navigation.querySelectorAll('.js-menu-item');

        this.init();
    }

    init() {
        if (this.$burger) {
            this.$burger.addEventListener('click', this.onBurgerClick.bind(this));
        }
        this.menuItems;
    }

    onBurgerClick() {
        // this.$burger.classList.toggle('burger--active');
        this.burgerAction();
    }
}
