import '../../../assets/icons/icon-arrow-back.svg';
import '../../../assets/icons/icon-arrow-forward.svg';
import '../../../assets/icons/icon-arrow-down.svg';
import '../../../assets/icons/icon-questions-arrow.svg';
import '../../../assets/icons/popup-closed.svg';
import '../../../assets/icons/telegram.svg';
import '../../../assets/icons/whatsapp.svg';
import '../../../assets/icons/arrow-up.svg';
import '../../../assets/icons/why-1.svg';
import '../../../assets/icons/why-2.svg';
import '../../../assets/icons/why-3.svg';
import '../../../assets/icons/why-4.svg';
import '../../../assets/icons/why-5.svg';
import '../../../assets/icons/why-6.svg';
import '../../../assets/icons/order.svg';
import '../../../assets/icons/thermometer.svg';
import '../../../assets/icons/thermometer-cold.svg';
import '../../../assets/icons/percent.svg';
import '../../../assets/icons/icon-checkbox.svg';
import '../../../assets/icons/icon-popup-closed.svg';
